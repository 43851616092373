<template>
  <div class="">
    <div class="is-flex is-justify-content-flex-end mb-2 mt-2">
      <div class="dropdown is-right is-hoverable mb-2">
        <div class="dropdown-trigger">
          <button
            class="button is-dark is-small"
            aria-haspopup="true"
            aria-controls="dropdown-menu4"
          >
            <span v-if="$parent.user">
              {{ $parent.user.UserDisplayName }}
            </span>
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div
          class="dropdown-menu"
          id="dropdown-menu4"
          role="menu"
          style="min-width: auto"
        >
          <div class="dropdown-content has-text-right">
            <a
              @click.prevent="$parent.doLogout"
              href="#"
              class="dropdown-item pr-3"
            >
              <i class="far fa-times-circle mr-2 has-text-danger"></i>
              {{ $t("log_out") }}
            </a>
          </div>
        </div>
      </div>
      <language-switch></language-switch>
    </div>
    <div class="pb-1" style="position: relative">
      <ul
        class="columns clearfix is-size-7 mb-0 pb-3 steps-nav"
        v-if="$route.path != '/' && $parent.event_details.ProjectState"
      >
        <li class="column p-0 has-text-centered">
          <router-link to="/" target="_blank" class="router-link-exact-active">
            <span
              class="step icenter"
              :class="{
                'step-is-checked':
                  $parent.event_details.ProjectState.Caption == 'Confirmed',
                'has-background-danger-light has-text-danger':
                  $parent.event_details.ProjectState.Caption == 'Canceled',
              }"
            >
              <span
                v-if="$parent.event_details.ProjectState.Caption != 'Confirmed'"
                >1</span
              >
              <span v-else>
                <i class="fa fa-check has-text-white"></i>
              </span>
            </span>
            <span v-if="$parent.currentEvent == 0"
              >{{ $t("select_project") }}
            </span>
          </router-link>
          <div v-if="$parent.event_details.Caption" class="mt-2">
            <div
              class="organizer has-text-green-light"
              v-if="$parent.event_details.Address_Customer"
            >
              {{ $parent.event_details.Address_Customer.Company }}
            </div>
            <div v-if="$parent.event_details.Caption" class="event">
              {{ $parent.event_details.Caption }}
            </div>

            <div class="organizer">
              <!-- <i class="fa fa-user has-text-success"></i> {{socket_clients.clients}} <br>    -->
              <a href="" @click.prevent="" title="Edit event dates">
                <i class="far fa-calendar-alt"></i>
              </a>
              <small class="">
                {{
                  ($parent.jobs_requests.length > 0
                    ? $parent.jobs_requests[0].DayTimeOut
                    : $parent.event_details.StartDate) | customdate
                }}
                -
                {{
                  ($parent.jobs_requests.length > 0
                    ? $parent.jobs_requests[0].DayTimeIn
                    : $parent.event_details.EndDate) | customdate
                }}
              </small>
            </div>
          </div>
        </li>
        <li
          class="column p-0 has-text-centered"
          v-if="$parent.currentEvent != 0 && am_allowed('request.can_read')"
        >
          <router-link
            :to="'/delivery-request/' + $route.params.id"
            class="router-wrap"
          >
            <span
              class="step icenter"
              :class="{ 'step-is-checked': is_request_synced }"
            >
              <span v-if="is_request_synced">
                <i class="fa fa-check"></i>
              </span>
              <span v-else>2</span>
            </span>
            <!-- <span :class="{'has-text-success-dark' : is_request_synced}"> -->
            <div class="mt-2 nav-page-title">REQUEST</div>
          </router-link>
          <div v-if="not_synced_request_amount != 0" class="nav-warning-text">
            <i class="fa fa-exclamation-triangle nav-warning-text"></i>
            {{
              isNaN(not_synced_request_amount) ? 0 : not_synced_request_amount
            }}
            {{ $t("materials_amount") }}
            <!-- material<span v-if="not_synced_request_amount != 1">s</span> amount  -->
          </div>
        </li>
        <li
          class="column pb-0 has-text-centered"
          v-if="$parent.currentEvent != 0 && am_allowed('warehouse.can_read')"
        >
          <router-link
            :to="'/request-accept/' + $route.params.id"
            class="router-wrap"
          >
            <span
              class="step icenter"
              :class="{ 'step-is-checked': not_accepted_warehouse_amount == 0 }"
            >
              <span v-if="not_accepted_warehouse_amount == 0">
                <i class="fa fa-check"></i>
              </span>
              <span v-else>3</span>
            </span>
            <div
              class="mt-2"
              :class="{
                'step-is-synced':
                  this.$parent.ej_project_materials_requested.length > 0 &&
                  not_accepted_warehouse_amount == 0,
              }"
            >
              WAREHOUSES
            </div>
            <span
              class="nav-warning-text"
              v-if="not_accepted_warehouse_amount > 0"
            >
              <i
                class="fas fa-exclamation-triangle is-small nav-warning-text"
              ></i>
              {{
                isNaN(not_accepted_warehouse_amount)
                  ? 0
                  : not_accepted_warehouse_amount
              }}
              {{ $t("materials_amount_to_accept") }}
              <!-- material{{not_accepted_amount != 1 ? 's': ''}} amount to accept -->
            </span>
            <div
              class="has-text-warning"
              v-if="$parent.event_details.ProjectState.ID == 2"
            >
              Project not confirmed
            </div>
            <div
              class="has-text-danger"
              v-if="$parent.event_details.ProjectState.ID == 3"
            >
              Project cancelled
            </div>
          </router-link>
        </li>
        <li
          class="column pb-0 has-text-centered"
          v-if="
            $parent.currentEvent != 0 &&
            $parent.event_details.ProjectState.ID == 1 &&
            am_allowed('parameter.can_read')
          "
        >
          <router-link
            :to="'/delivery-parameters/' + $route.params.id"
            class="router-wrap"
          >
            <span
              class="step icenter"
              :class="{ 'step-is-checked': param_count > 0 }"
            >
              <span v-if="param_count > 0">
                <i class="fa fa-check"></i>
              </span>
              <span v-else> 4 </span>
            </span>
            <div class="mt-2">
              {{ $t("parameters") | uppercase }}
            </div>
          </router-link>
        </li>
        <li
          class="column pb-0 has-text-centered"
          v-if="
            $parent.currentEvent != 0 &&
            $parent.event_details.ProjectState.ID == 1 &&
            am_allowed('planning.can_read')
          "
        >
          <router-link
            :to="'/delivery-plan/' + $route.params.id"
            class="router-wrap"
          >
            <span class="step icenter">5</span>
            <div class="mt-2">
              {{ $t("planning") | uppercase }}
            </div>
          </router-link>
        </li>
        <li
          class="column pb-0 has-text-centered"
          v-if="
            $parent.currentEvent != 0 &&
            $parent.event_details.ProjectState.ID == 1 &&
            am_allowed('planning.can_read')
          "
        >
          <router-link
            :to="'/loading-module/' + $route.params.id"
            class="router-wrap"
          >
            <span class="step icenter">6</span>
            <div class="mt-2">
              {{ $t("loading") | uppercase }}
            </div>
          </router-link>
        </li>
        <!--<li class="column pb-0   has-text-centered" v-if="$parent.currentEvent != 0 && $parent.event_details.ProjectState.ID == 1">
                    <router-link :to="'/delivery-routes/' + $route.params.id">
                        <span class="step icenter">6</span>  {{ $t("routes") }}
                    </router-link>
				</li>-->
      </ul>
      <div
        class="is-flex is-justify-content-flex-end"
        style="position: absolute; bottom: 8px; right: 6px"
      >
        <div v-if="socket_clients" class="m-0">
          <div
            v-if="socket_clients.clients"
            class="is-flex is-justify-content-flex-end is-align-items-center"
            style=""
          >
            <div class="tags has-addons m-0">
              <span class="is-small mb-2">
                <i class="fa fa-solid fa-users"></i>
              </span>
              <span
                v-for="(user, index) in socket_list"
                :key="index"
                class="tag is-small is-dark ml-2 mt-1"
              >
                {{ user.name }}
              </span>
            </div>
            <ul class="is-pulled-left mb-2">
              <li v-if="socket_clients"></li>
            </ul>
          </div>
        </div>
        <span
          v-if="$route.path != '/'"
          @click.prevent="open_chat"
          class="mt-2 is-size-7 ml-2 is-clickable"
          style=""
        >
          <i class="far fa-comments fa-lg"></i>
        </span>
        <chat-component
          :show_chat="show_chat"
          @close_chat="close_chat"
          @comment_count="get_comment_count"
        >
        </chat-component>
      </div>
    </div>
  </div>
</template>

<script>
import socketMixin from "@/socket-mixin.js";
import permissionMixin from "@/permissions-mixin.js";
export default {
  components: {
    "chat-component": () => import("@/components/chat/chat-component.vue"),
    "language-switch": () =>
      import("@/components/language/language-switch.vue"),
  },
  mixins: [socketMixin, permissionMixin],
  data() {
    return {
      show_animation: true,
      show_user_tab: false,
      show_chat: false,
      comments_count: 0,
      not_accepted_amount: 0,
      // socket_connected: false,
    };
  },
  methods: {
    open_user_tab() {
      this.show_user_tab = !this.show_user_tab;
      this.show_animation != this.show_animation;
    },
    close_user_tab() {
      this.show_user_tab = false;
    },
    open_chat() {
      this.show_chat = !this.show_chat;
    },
    close_chat() {
      this.show_chat = false;
      this.new_comment = {};
    },
    get_comment_count(a) {
      this.comments_count = a;
    },
  },

  destroyed() {
    this.destroy_socket();
  },
  computed: {
    project_id() {
      // var o = ''
      // if( JSON.parse(localStorage.getItem("event_details"))){
      //     o = JSON.parse(localStorage.getItem("event_details")).IdProject
      // }
      // return o
      // console.log(this.$route.path)

      var url = this.$route.path;
      // console.log(url.split('/'))
      var proj_id = url.split("/")[2];

      return proj_id;
    },
    socket_list() {
      var socket_clients_1 = this.socket_clients.list.filter((r) => r != null);
      let unique = socket_clients_1.reduce((res, itm) => {
        let result = res.find(
          (item) => JSON.stringify(item.name) == JSON.stringify(itm.name)
        );
        if (!result) return res.concat(itm);
        return res;
      }, []);
      // console.log('unique', unique)
      return unique;

      // Backup 2 (never worked)
      // let unique = [...new Set(socket_clients_1.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i))
      // console.log('unique', unique)

      // Backup 1 (works)
      // var a = []
      // socket_clients_1.map((sc) => {
      //     if(a.length == 0){
      //         a.push(sc)
      //     } else {
      //         a.map(aa => {
      //             if(sc.name != aa.name){
      //                 a.push(sc)
      //             }
      //         })
      //     }
      // })
      // console.log('socket_clients_1', socket_clients_1)

      // Backup return
      // return this.socket_clients.list.filter(r=> r != null)
    },
    is_request_synced() {
      let is_synced = true;
      if (this.$parent.ej_project_materials_requested.length) {
        this.$parent.ej_project_materials_requested.forEach((ejm) => {
          let amount = 0;

          this.$parent.tetris_project_materials_requested.forEach((tm) => {
            if (ejm.IdST == tm.material_id) {
              amount = amount + parseInt(tm.amount);
            }
          });
          if (amount != ejm.Qty) is_synced = false;
        });
      } else is_synced = false;
      return is_synced;
    },
    not_synced_request_amount() {
      let not_accepted_amount = 0;
      if (this.$parent.ej_project_materials_requested.length) {
        this.$parent.ej_project_materials_requested.forEach((ejm) => {
          let amount = 0;

          this.$parent.tetris_project_materials_requested.forEach((tm) => {
            if (ejm.IdST == tm.material_id) {
              amount = amount + parseInt(tm.amount);
            }
          });
          if (amount != ejm.Qty) {
            not_accepted_amount += ejm.Qty - amount;
          }
        });
      }
      return not_accepted_amount;
    },
    is_warehouse_accepted() {
      let is_assigned = false;
      if (this.$parent.ej_project_materials_requested.length) {
        this.$parent.ej_project_materials_requested.forEach((ejm) => {
          if (ejm.date_amount)
            ejm.date_amount.forEach((da) => {
              if (da.warehouse_job == null) is_assigned = false;
              else is_assigned = true;
            });
          else is_assigned = false;
        });
      }
      return is_assigned;
    },
    not_accepted_warehouse_amount() {
      let amount = 0;
      if (this.$parent.ej_project_materials_requested.length) {
        this.$parent.ej_project_materials_requested.forEach((ejm) => {
          if (ejm.date_amount)
            ejm.date_amount.forEach((da) => {
              if (da.warehouse_job == null && !da.m_connected_project) {
                amount = amount + parseInt(da.Qty);
              }
            });
          else amount += parseInt(ejm.Qty);
        });
      }
      return amount;
    },
    param_count() {
      return this.$parent.project_parameters_count;
    },
  },
  created() {
    // this.get_comments()
    this.namespace = this.project_id;
    if (!this.socket_connected) this.socket_connect();
    this.$parent.get_project_parameters_count(this.project_id);
  },
};
</script>

<style lang="scss" scoped>
.event {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.organizer {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
}
.steps-wrap {
  // background: rgb(0,0,0);
  background: linear-gradient(
    0deg,
    rgb(100, 100, 100) 1%,
    rgb(175, 175, 175) 1%,
    rgb(232, 231, 236) 8%,
    rgb(255, 255, 255) 100%
  );
}
.chat-icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.step-is-checked {
  background: #3a5369;
  color: #fff;
}
.steps-nav {
  align-items: baseline !important;
}
.step-is-synced {
  color: #8adede;
}
.nav-step {
  border: solid 1px #fff;
}
.nav-warning-text {
  color: #f0ff77;
}
.router-wrap {
  color: #c6c8ca;
}
</style>
